import { Inject, Injectable } from '@angular/core'

// eslint-disable-next-line no-use-before-define
export enum WayMode {
  WAY_PARTNER = 'WAY_PARTNER',
  WAY_D = 'WAY_D',
}

@Injectable({
  providedIn: 'root',
})
export class WayModeService {

  private _mode: WayMode = WayMode.WAY_PARTNER

  constructor(@Inject('WaySuffix') public suffix: string){
    if(suffix === 'D'){
      this.setMode(WayMode.WAY_D)
    }
  }

  setMode(mode: WayMode) {
    this._mode = mode
  }

  getMode() {
    return this._mode
  }
}
