import { Observable, Subject } from 'rxjs';
import { CalendarEvent } from 'angular-calendar';
import { ActivatedRoute, Params } from '@angular/router';
import { JafConcept } from '@way-lib-jaf/concept';
import { CGenMissionRow , CComCommandeRow } from '@way-lib-jaf/rowLoader';
import { Jaf } from '@way-lib-jaf/jaf';

export enum OrderType {
  PENDING   = 'pending',
  ACCEPTED  = 'accepted',
  CANCELLED = 'cancelled',
}

export class CGenMission extends JafConcept {
  public primary = 'MIS_ID';

  public hasService = true;

  protected name = 'nf_gen_mission';

  protected class = 'C_Gen_Mission';

  protected rowClass = 'CGenMissionRow';

  protected trigramme = 'MIS';

  private newInstance: CGenMissionRow;

  private _calendar: Subject<Array<CalendarEvent>>;

  public listeSMIChauffeurEnCours = [
    CGenMissionRow.SMI_CHAUFFEUR_OK,
    CGenMissionRow.SMI_MISSION_DEMARRER,
    CGenMissionRow.SMI_EN_PLACE,
    CGenMissionRow.SMI_ATTENTE_ATTRIBUTION,
  ];

  get calendar(): Subject<Array<CalendarEvent>> {
    if (this._calendar === undefined) {
      this.build_calendar();
    }
    return this._calendar;
  }

  private _billable: Array<CGenMissionRow>;

  get billable() {
    if (this._billable === undefined) {
      this.build_billable();
    }
    return this._billable;
  }

  build_billable() {
    if (this._billable === undefined) {
      this._billable = new Array<CGenMissionRow>();
      this.bindfilter(['MIS_ID', 'MIS_SMI_ID'], ['build_all', 'build_billable']);
    }
    this.arrayMerge(
      this._billable,
      this.allFinalized
        .filter((value: CGenMissionRow) => {
          return [
            CComCommandeRow.SCO_IN_PROGRESS,
            CComCommandeRow.SCO_SENT_TO_SUBCONTRACTOR,
            CComCommandeRow.SCO_QUOTE_OK,
            CComCommandeRow.SCO_QUOTE_CONFIRMED,
            CComCommandeRow.SCO_MODIFING,
          ].includes(value.MIS_COM_ID?.COM_SCO_ID);
        })
        .sort(this.sort_bydate),
    );
  }

  private _tbc: Array<CGenMissionRow>;

  /*------------------------------------------------------------------------------------------------
     liste tbc : liste des mission à confirmer
   */
  get tbc() {
    if (this._tbc === undefined) {
      this.build_tbc();
    }
    return this._tbc;
  }

  private _inprocess: Array<CGenMissionRow>;

  /*------------------------------------------------------------
  liste inprocess : liste des mission dans les statuts chauffeur ok, mission démarrée et POB
   */
  get inprocess() {
    if (this._inprocess === undefined) {
      this.build_inprocess();
    }
    return this._inprocess;
  }

  private _toclose: Array<CGenMissionRow>;

  /**
   * liste toclose : liste des mission en statut terminé
   */
  get toclose() {
    if (this._toclose === undefined) {
      this.build_toclose();
    }
    return this._toclose;
  }

  private _denied: Array<CGenMissionRow>;

  get denied() {
    if (this._denied === undefined) {
      this.build_denied();
    }
    return this._denied;
  }

  private _pending: Array<CGenMissionRow>;

  get pending() {
    if (this._pending === undefined) {
      this.build_pending();
    }
    return this._pending;
  }

  private _accepted: Array<CGenMissionRow>;

  get accepted() {
    if (this._accepted === undefined) {
      this.build_accepted();
    }
    return this._accepted;
  }

  private _dashboard: CGenMissionRow;

  /*------------------------------------------------------------
   * dashboard : choose the mission to draw on the home page :
   * the oldest mission that's not closed
   */
  get dashboard() {
    if (this._dashboard === undefined) {
      this.build_dashboard();
    }
    return this._dashboard;
  }

  build_dashboard() {
    if (this._dashboard === undefined) {
      this._dashboard = null;
      this.bindfilter(['MIS_ID', 'EPR_ID'], ['build_all', 'build_inprocess', 'build_dashboard']);
      // on designe les champs qui doivent reconstruire cette liste
    }
    this._dashboard = null;
    this.inprocess.forEach((mis: CGenMissionRow) => {
      if (
        this._dashboard === null ||
        (this._dashboard.MIS_DATE_DEBUT === mis.MIS_DATE_DEBUT &&
          (mis.MIS_SMI_ID === CGenMissionRow.SMI_MISSION_DEMARRER ||
            mis.MIS_SMI_ID === CGenMissionRow.SMI_EN_PLACE) &&
          this._dashboard.MIS_HEURE_FIN > mis.MIS_HEURE_DEBUT)
      ) {
        this._dashboard = mis;
      }
    });

    if (this._dashboard === null && this.tbc.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      this._dashboard = this.tbc[0];
    }

    if (this._dashboard === null && this.toclose.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      this._dashboard = this.toclose[0];
    }

    if (this._dashboard) {
      this._dashboard.build_btnStart();
      if (!this._dashboard.btnStartLabel || this._dashboard.btnStartLabel.length === 0) {
        this._dashboard = null;
      }
    }
    // console.log('build_dashboard');
  }

  sort_bydate(m1, m2) {
    return m1.startTime - m2.startTime;
  }

  build_calendar() {
    if (this._calendar === undefined) {
      this._calendar = new Subject();
    }

    const liste = [];
    this.inprocess.forEach((mission: CGenMissionRow) => {
      const event: CalendarEvent<CGenMissionRow> = mission;
      liste.push(event);
    });
    this._calendar.next(liste);
    // console.log('build_calendar');

  }

  filter_inprocess(value: CGenMissionRow) {
    return this.listeSMIChauffeurEnCours.includes(value.MIS_SMI_ID);
  }

  build_inprocess() {
    if (this._inprocess === undefined) {
      this._inprocess = new Array<CGenMissionRow>();
      // on designe les champs qui doivent reconstruire cette liste
      this.bindfilter(['MIS_ID'], ['build_all', 'build_inprocess']);
    }
    this.arrayMerge(
      this._inprocess,
      this.allFinalized
        .filter((value: CGenMissionRow) => {
          return this.listeSMIChauffeurEnCours.includes(value.MIS_SMI_ID)
        })
        .sort(this.sort_bydate),
    );
    // console.log(`nb inprocess=${this._inprocess.length}`);
    let missionOld = null;
    this._inprocess.forEach((mission) => {
      if (missionOld === null) {
        missionOld = mission;
      } else {
        const lieFin   = missionOld.lastLieu;
        const lieDebut = mission.firstLieu;
        if (lieFin && lieDebut) {
          const distance = Jaf.getDistanceKm(
            lieDebut.LIE_LAT,
            lieDebut.LIE_LNG,
            lieFin.LIE_LAT,
            lieFin.LIE_LNG,
          );
          /*
          console.log(
            `distance entre ${lieFin.LIE_FORMATED} et ${lieDebut.LIE_FORMATED}=${distance}`,
          );      */
          missionOld.separator = {
            estimation: {
              distance: `${Math.round(Jaf.getDistanceRouteByDistance(distance))} km`,
              time    : `${Math.round((Jaf.getTempsByDistance(distance) * 2) / 60000)} min`,
            },
          };
        }
      }
    });
    // console.log(`build_inprocess ${this._inprocess.length} rows`);
  }

  build_toclose() {
    if (this._toclose === undefined) {
      this._toclose = new Array<CGenMissionRow>();

      // on designe les champs qui doivent reconstruire cette liste
      this.bindfilter(['MIS_ID', 'MIS_SMI_ID'], ['build_all', 'build_toclose']);
    }
    this.arrayMerge(
      this._toclose,
      this.allFinalized
        .filter((value: CGenMissionRow) => {
          return value.MIS_SMI_ID === CGenMissionRow.SMI_MISSION_TERMINER;
        })
        .sort(this.sort_bydate),
    );
    // console.log('build_toclose');

  }

  build_pending() {
    if (this._pending === undefined) {
      // on designe les champs qui doivent reconstruire cette liste
      this.bindfilter(['MIS_ID', 'MIS_SMI_ID'], ['build_all', 'build_pending']);
    }
    this._pending = this.getMissionsFromOrders(OrderType.PENDING);
  }

  build_accepted() {
    if (this._accepted === undefined) {
      // on designe les champs qui doivent reconstruire cette liste
      this.bindfilter(['MIS_ID', 'MIS_SMI_ID'], ['build_all', 'build_accepted']);
    }
    this._accepted = this.getMissionsFromOrders(OrderType.ACCEPTED);
  }

  build_denied() {
    if (this._denied === undefined) {
      // on designe les champs qui doivent reconstruire cette liste
      this.bindfilter(['MIS_ID', 'MIS_SMI_ID'], ['build_all', 'build_denied']);
    }
    this._denied =  this.getMissionsFromOrders(OrderType.CANCELLED);
  }

  filter_tbc(value: CGenMissionRow) {
    return (
      value.MIS_SMI_ID === CGenMissionRow.SMI_ENVOYER_AU_CHAUFFEUR ||
      (value.MIS_FLAG_MODIFIE === '1' && this.listeSMIChauffeurEnCours.includes(value.MIS_SMI_ID))
    );
  }

  build_tbc() {
    if (this._tbc === undefined) {
      this._tbc = new Array<CGenMissionRow>();
      this.bindfilter(['MIS_ID', 'MIS_SMI_ID'], ['build_all', 'build_tbc']); // on designe les champs qui doivent reconstruire cette liste
    }
    this.arrayMerge(
      this._tbc,
      this.allFinalized
        .filter((value: CGenMissionRow) => {
          return (
            value.MIS_SMI_ID === CGenMissionRow.SMI_ENVOYER_AU_CHAUFFEUR || value.MIS_SMI_ID === CGenMissionRow.SMI_ATTENTE_ATTRIBUTION
          );
        })
        .sort(this.sort_bydate),
    );
   // console.log(`build_tbc ${this._tbc.length} rows`);
  }

  getNewInstance(database: string, data?: {}) {
    this.newInstance = new CGenMissionRow(this, data || {}, database);
    return this.newInstance;
  }

  getMissionFromRoute(route: ActivatedRoute) {
    const observable = new Observable((subscriber) => {
      route.params.subscribe((params: Params) => {
        if (params.misId || route.snapshot.queryParams.misId) {
          const mission = this.getRow(
            params.misId || route.snapshot.queryParams.misId,
            params.database,
          );
          if (mission) {
            subscriber.next(mission);
          } else {
            subscriber.error('Mission not found');
          }
        } else {
          subscriber.error('Mission id not found');
        }
      });
    });
    return observable;
  }

  private _allFinalized: Array<CGenMissionRow>;

  get allFinalized() {
    this.build_allFinalized();
    return this._allFinalized;
  }

  build_allFinalized() {
    this._allFinalized = <CGenMissionRow[]>this.all.filter((mission:CGenMissionRow) => {
      return !mission.isDraft()
    })
  }

  getMissionsFromOrders(orderType: OrderType): Array<CGenMissionRow> {
    let status:string;
    switch (orderType) {
      case OrderType.PENDING:
        status = CComCommandeRow.SCO_IN_PROGRESS;
        break;
      case OrderType.ACCEPTED:
        status = CComCommandeRow.SCO_QUOTE_CONFIRMED;
        break;
      case OrderType.CANCELLED:
        status = CComCommandeRow.SCO_CANCELLED;
        break;
      default:
        status = CComCommandeRow.SCO_IN_PROGRESS;
    }
    const missions: CGenMissionRow[] = [];
    this.cm.getConcept('C_Com_Commande')[orderType].forEach((commande: CComCommandeRow) => {
      commande.miss.forEach((mission: CGenMissionRow) => {
        if (mission.MIS_COM_ID.COM_SCO_ID === status) {
          if (!missions.find((acceptedMission: CGenMissionRow) => acceptedMission.MIS_ID === mission.MIS_ID)) {
            missions.push(mission);
          }
        }
      });
    });
    return missions;
  }

  rebuildQuoteStatus(): void{
    this.build_pending()
    this.build_accepted()
    this.build_denied()
  }

}
