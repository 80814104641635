import { CGenMissionRow, CGeoLieuRow, CGenPresenceRow } from '@way-lib-jaf/rowLoader';
import { JafRow } from '@way-lib-jaf/row';
import { Jaf } from '@way-lib-jaf/jaf';
import { InfoVol } from '@way-lib/common/interfaces/flystat';
import { WayChamp } from '@way-lib/common/decorator/WayChamp';
import { WayBuild } from '@way-lib/common/decorator/WayBuild';
import { WayChampBuild } from '@way-lib/common/decorator/WayChampBuild';
import { MissionLocation } from '../../mission/interfaces/mission';

export class CGenEtapePresenceRow extends JafRow {
  @WayChamp({
    label: '%LAB_GEN_ETAPEPRESENCE_ID%',
    class: 'Id',
  })
  public EPR_ID;

  @WayChamp({
    label  : '%LAB_GEN_ETAPEPRESENCE_MIS_ID%',
    class  : 'Liaison',
    liaison: 'C_Gen_Mission',
    indexed: true,
  })
  public EPR_MIS_ID: CGenMissionRow;

  @WayChamp({
    label  : '%LAB_GEN_ETAPEPRESENCE_LIE_ID%',
    class  : 'Liaison',
    liaison: 'C_Geo_Lieu',
  })
  public EPR_LIE_ID: CGeoLieuRow;

  @WayChamp({
    label: '%LAB_GEN_ETAPEPRESENCE_TRI%',
    class: 'Tri',
  })
  public EPR_TRI;

  @WayChamp({
    label: '%LAB_GEN_ETAPEPRESENCE_NUM_TRANSPORT%',
    class: 'Textarea',
  })
  public EPR_NUM_TRANSPORT: string;

  @WayChamp({
    label: '%LAB_GEN_ETAPEPRESENCE_HEURE_TRANSPORT%',
    class: 'Texte',
  })
  public EPR_HEURE_TRANSPORT: string;

  @WayChamp({
    label: '%LAB_GEN_ETAPEPRESENCE_HEURE_ARRIVER%',
    class: 'Time',
  })
  public EPR_HEURE_ARRIVER: string;

  @WayChamp({
    label: '%LAB_GEN_ETAPEPRESENCE_HEURE_DEPART%',
    class: 'Time',
  })
  public EPR_HEURE_DEPART: string;

  @WayChamp({
    label: '%LAB_GEN_ETAPEPRESENCE_FLAG_ANNULER%',
    class: 'Flag',
  })
  public EPR_FLAG_ANNULER: string;

  @WayChamp({
    label: '%LAB_GEN_ETAPEPRESENCE_HEURE_DEBUT%',
    class: 'Time',
  })
  public EPR_HEURE_DEBUT: string;

  @WayChamp({
    label: '%LAB_GEN_ETAPEPRESENCE_DECALAGE%',
    class: 'Quantite',
  })
  public EPR_DECALAGE: string;

  @WayChamp({
    label: '%LAB_GEN_ETAPEPRESENCE_INFOS_VOL%',
    class: 'Textarea',
  })
  public EPR_INFOS_VOL: string;

  @WayChampBuild()
  public startTime: Date;

  @WayChampBuild()
  public passengerPickup: Array<CGenPresenceRow>;

  @WayChampBuild()
  public passengerDrop: Array<CGenPresenceRow>;

  public isFirst = false; // vrai si c'est le premier epr de la mission

  public isLast = false; // vrai si c'est le dernier epr de la mission

  public isActive = false; // vrai si c'est l'etape en cours

  get infoLocation(): MissionLocation {
    if(!this.EPR_LIE_ID) return null

    const { infoLocation, LIE_TLI_ID } = this.EPR_LIE_ID;

    if (!infoLocation) {
      return null;
    }
    
    if (LIE_TLI_ID === '1' && this.EPR_INFOS_VOL) {
      const infoVol: InfoVol = JSON.parse(this.EPR_INFOS_VOL) || {};
      if (this.EPR_NUM_TRANSPORT) {
        infoVol.numero = this.EPR_NUM_TRANSPORT;
      }
      if (infoVol.avance) {
        infoVol.avance = +infoVol.avance;
      }
      if (Object.keys(infoVol).length > 0) {
        Object.keys(infoVol).forEach(key => {
          if (infoVol[key] === null) {
            delete(infoVol[key]);
          }
        });
        infoLocation.infoVol = infoVol;
      }
      infoLocation.stepCommentary = this.EPR_HEURE_TRANSPORT;
    } else {
      infoLocation.stepCommentary = this.EPR_NUM_TRANSPORT;
    }
    return infoLocation;
  }

  get labelLieu() {
    return this.EPR_LIE_ID.label;
  }

  @WayBuild(['EPR_ID'])
  build_etapes() {
    if (typeof this.EPR_MIS_ID === 'object')
      this.EPR_MIS_ID.launchBuildByRow(['build_eprs', 'build_etapes']);
  }

  build_startTime() {
    this.startTime = null;
    if (typeof this.EPR_MIS_ID === 'object' && this.EPR_MIS_ID.MIS_DATE_DEBUT) {
      if (this.EPR_HEURE_DEBUT) {
        // console.log(`1 - ${this.EPR_MIS_ID.MIS_DATE_DEBUT} ${this.EPR_HEURE_DEBUT}`);
        this.startTime = Jaf.getDate(`${this.EPR_MIS_ID.MIS_DATE_DEBUT} ${this.EPR_HEURE_DEBUT}`);
      } else if (this.isFirst) {
        if (this.EPR_MIS_ID.MIS_HEURE_DEBUT) {
          // console.log(`2 - ${this.EPR_MIS_ID.MIS_DATE_DEBUT} ${this.EPR_MIS_ID.MIS_HEURE_DEBUT}`);
          this.startTime = Jaf.getDate(
            `${this.EPR_MIS_ID.MIS_DATE_DEBUT} ${this.EPR_MIS_ID.MIS_HEURE_DEBUT}`,
          );
        } else {
          this.startTime = Jaf.getDateSansHeure(this.EPR_MIS_ID.MIS_DATE_DEBUT);
        }
      } else if (this.isLast) {
        if (this.EPR_MIS_ID.MIS_HEURE_FIN) {
          // console.log(`2 - ${this.EPR_MIS_ID.MIS_DATE_DEBUT} ${this.EPR_MIS_ID.MIS_HEURE_DEBUT}`);
          this.startTime = Jaf.getDate(
            `${this.EPR_MIS_ID.MIS_DATE_DEBUT} ${this.EPR_MIS_ID.MIS_HEURE_FIN}`,
          );
        } else {
          this.startTime = Jaf.getDateSansHeure(this.EPR_MIS_ID.MIS_DATE_DEBUT);
        }
      }
    }
    // console.log('je build_startTime sur epr'+this.EPR_ID,this.startTime);
  }
}
