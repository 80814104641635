import { JafRow } from '@way-lib-jaf/row';
import { WayChamp } from '@way-lib/common/decorator/WayChamp';

export class CGdsEvenementChauffeurRow extends JafRow {
  @WayChamp({
    label: '%LAB_GDS_EVENEMENTCHAUFFEUR_ID%',
    class: 'Id',
  })
  public EVC_ID;

  @WayChamp({
    label: '%LAB_GDS_EVENEMENTCHAUFFEUR_CEV_ID%',
    class: 'Liaison',
    // liaison: 'C_Mod_ClasseEvenement',
  })
  public EVC_CEV_ID;

  @WayChamp({
    label: '%LAB_GDS_EVENEMENTCHAUFFEUR_DATE%',
    class: 'Date',
  })
  public EVC_DATE;

  @WayChamp({
    label: '%LAB_GDS_EVENEMENTCHAUFFEUR_PARAMS%',
    class: 'Textarea',
  })
  public EVC_PARAMS;

  @WayChamp({
    label: '%LAB_GDS_EVENEMENTCHAUFFEUR_CPT_CLASS%',
    class: 'Texte',
  })
  public EVC_CPT_CLASS;

  @WayChamp({
    label: '%LAB_GDS_EVENEMENTCHAUFFEUR_PRIMARY%',
    class: 'Texte',
  })
  public EVC_PRIMARY;

  @WayChamp({
    label: '%LAB_GDS_EVENEMENTCHAUFFEUR_EVE_ID%',
    class: 'Liaison',
    // liaison: 'C_Mod_Evenement',
  })
  public EVC_EVE_ID;

  @WayChamp({
    label: '%LAB_GDS_EVENEMENTCHAUFFEUR_FLAG_NOTIFICATION%',
    class: 'Flag',
  })
  public EVC_FLAG_NOTIFICATION;

  @WayChamp({
    label: '%LAB_GDS_EVENEMENTCHAUFFEUR_INS_ID%',
    class: 'Liaison',
    // liaison: 'C_Gds_Installation',
  })
  public EVC_INS_ID;

  @WayChamp({
    label  : '%LAB_GDS_EVENEMENTCHAUFFEUR_CHU_ID%',
    class  : 'Liaison',
    liaison: 'C_Gen_Chauffeur',
  })
  public EVC_CHU_ID;

  @WayChamp({
    label: '%LAB_GDS_EVENEMENTCHAUFFEUR_LECTURE_DATE%',
    class: 'Date',
  })
  public EVC_LECTURE_DATE;
}
