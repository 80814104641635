import { formatDate } from '@angular/common';
import {
  CGenMissionRow , CGenEtapePresenceRow , CGdsCercleRow ,
  CGeoLieuRow , CGenCategorieChauffeurRow , CGdsInstallationRow
} from '@way-lib-jaf/rowLoader';
import { Jaf } from '@way-lib-jaf/jaf';
import { JafRow } from '@way-lib-jaf/row';
import { WayChamp } from '@way-lib/common/decorator/WayChamp';
import { BourseDtr, CMIS, Epr } from '../../mission/interfaces/bourse-dtr';

export class CGdsDtrRow extends JafRow {
  static DTR_AVAILABLE = '0';

  static DTR_SDT_PENDING = '1';

  static DTR_SDT_PENDING_PRICING = '2';

  static DTR_SDT_ACCEPTED = '3';

  static DTR_SDT_PENDING_CLIENT_VALIDATION = '4';

  static DTR_SDT_IN_PROGRESS = '5';

  static DTR_SDT_DONE = '6';

  static DTR_SDT_TRANSFER_REQUEST = '10';

  static DTR_SDT_TRANSFER_ACCEPTED = '11';

  static DTR_SDT_SUBCONTRACTOR_REFUSED = '12';

  static DTR_SDT_CONTRACTOR_REFUSED = '13';

  static DTR_SDT_CONTRACTOR_CANCELLED = '14';

  static DTR_SDT_SUBCONTRACTOR_CANCELLED = '15';

  static DTR_SDT_CONTRACTOR_CANCELLED_MISSION = '16';

  static DTR_SDT_CONTRACTOR_ACCEPTED_CANCEL_REQUEST = '17';

  static DTR_SDT_CONTRACTOR_REFUSED_CANCEL_REQUEST = '18';

  static DTR_SDT_SUBCONTRACTOR_REQUESTED_CANCELLING = '19';

  static DTR_SDT_CANCEL_ACKNOWLEDGED_BY_SUBCONTRACTOR = '20';

  static DTR_SDT_SUBCONTRACTOR_REFUSED_CANCEL_REQUEST = '21';

  static DTR_SDT_PENDING_AUTOMATIC_PROCESSING = '22';

  @WayChamp({
    label: '%LAB_GDS_DTR_ID%',
    class: 'Id',
  })
  public DTR_ID;

  @WayChamp({
    label: '%LAB_GDS_DTR_DO_INS_ID%',
    class: 'Liaison',
  })
  public DTR_DO_INS_ID;

  @WayChamp({
    label: '%LAB_GDS_DTR_ST_INS_ID%',
    class: 'Liaison',
  })
  public DTR_ST_INS_ID;

  @WayChamp({
    label: '%LAB_GDS_DTR_DATE_MISSION%',
    class: 'Date',
  })
  public DTR_DATE_MISSION;

  @WayChamp({
    label: '%LAB_GDS_DTR_INFOS%',
    class: 'Textarea',
  })
  public DTR_INFOS;

  @WayChamp({
    label: '%LAB_GDS_DTR_DO_MONTANT%',
    class: 'Textarea',
  })
  public DTR_DO_MONTANT;

  @WayChamp({
    label: '%LAB_GDS_DTR_ST_MONTANT%',
    class: 'Montant',
  })
  public DTR_ST_MONTANT;

  @WayChamp({
    label: '%LAB_GDS_DTR_MONTANT_COMMISSION%',
    class: 'Montant',
  })
  public DTR_MONTANT_COMMISSION;

  @WayChamp({
    label: '%LAB_GDS_DTR_DATE_CREATION%',
    class: 'Date',
  })
  public DTR_DATE_CREATION;

  @WayChamp({
    label: '%LAB_GDS_DTR_SDT_ID%',
    class: 'Liaison',
  })
  public DTR_SDT_ID;

  @WayChamp({
    label: '%LAB_GDS_DTR_DO_MIS_ID%',
    class: 'Quantite',
  })
  public DTR_DO_MIS_ID;

  @WayChamp({
    label: '%LAB_GDS_DTR_ST_MIS_ID%',
    class: 'Quantite',
  })
  public DTR_ST_MIS_ID;

  get acceptedVehicles(): string {
    switch (this.DTR_INFOS.filter.NPR_ID.join(',')) {
      case '2,3':
        return 'Véhicules affaire et luxe acceptés';

      case '3':
        return 'Véhicules luxe acceptés';

      default:
        return 'Tous véhicules acceptés';
    }
  }

  build_Info(mission: CGenMissionRow): BourseDtr {
    const dtrInfo: BourseDtr = {
      MIS_DATE_DEBUT : mission.MIS_DATE_DEBUT,
      MIS_HEURE_DEBUT: Jaf.getTimeWithSeconds(
        mission.isAirportMission()
          ? mission.eprs[0]?.EPR_HEURE_DEBUT
          : mission.MIS_HEURE_DEBUT || '00:00:00',
      ),
      MIS_HEURE_FIN               : Jaf.getTimeWithSeconds(mission.MIS_HEURE_FIN || '00:00:00'),
      MIS_PAX                     : mission.MIS_PAX || '0',
      MIS_BAGAGE                  : mission.MIS_BAGAGE || '0',
      epr                         : mission.eprs.map((epr: CGenEtapePresenceRow) => this.buildEpr(epr)),
      MIS_TSE_ID                  : mission.MIS_TSE_ID || '',
      MIS_TVE_ID                  : mission.MIS_TVE_ID || '',
      FLAG_INFO_PASSAGER          : '1',
      MIS_CEL_PASSAGER_MODE_NORMAL: mission.MIS_CEL_PASSAGER_MODE_NORMAL || '',
      TYPE_APPEL_OFFRE            : 'BOURSE',
      MIS_CMI                     : mission.MIS_CMI || '',
      filter                      : {
        VOI_ID: [],
        CER_ID: [],
        MOE_ID: [],
        NPR_ID: [],
        EQU_ID: [],
        CCH_ID: [],
      },
      DTR_DO_MONTANT    : this.DTR_DO_MONTANT || '',
      DTR_ST_MONTANT    : this.DTR_ST_MONTANT || '',
      DTR_DO_INS_ID     : this.DTR_DO_INS_ID || '',
      DTR_DO_MIS_ID     : this.DTR_DO_MIS_ID || '',
      DTR_DATE_MISSION  : this.DTR_DATE_MISSION || '',
      MIS_NOTE_CHAUFFEUR: mission.MIS_NOTE_CHAUFFEUR || ''
    };
    const cercles            = this.concept.cm.getConcept('C_Gds_Cercle').all;
    cercles.forEach((cercle: CGdsCercleRow) => {
      if (cercle.CER_ID && dtrInfo.filter.CER_ID) {
        dtrInfo.filter.CER_ID.push(cercle.CER_ID);
      }
    });
    this.DTR_INFOS = dtrInfo;
    return dtrInfo;
  }

  buildEpr(epr: any): Epr {
    return {
      EPR_ID         : epr.EPR_ID || '',
      EPR_LIE_ID     : epr.EPR_LIE_ID.LIE_ID || '',
      EPR_MIS_ID     : epr.EPR_MIS_ID.MIS_ID || '',
      EPR_HEURE_DEBUT: epr.EPR_HEURE_DEBUT || '',
      EPR_TRI        : epr.EPR_TRI || '',
      EPR_INFOS_VOL  : epr.EPR_INFOS_VOL || '',
      LIE_LIBELLE    : epr.EPR_LIE_ID.LIE_LIBELLE || '',
      LIE_TLI_ID     : epr.EPR_LIE_ID.LIE_TLI_ID || '',
      LIE_FORMATED   : epr.EPR_LIE_ID.LIE_FORMATED || '',
      LIE_LAT        : epr.EPR_LIE_ID.LIE_LAT || '',
      LIE_LNG        : epr.EPR_LIE_ID.LIE_LNG || '',
      LIE_TIMEZONE   : epr.EPR_LIE_ID.LIE_TIMEZONE || '',
      LIE_PAY_ID     : epr.EPR_LIE_ID.LIE_PAY_ID || '',
    };
  }

  cancelDtr(): void {
    this.setDatas({
      DTR_SDT_ID: CGdsDtrRow.DTR_SDT_CONTRACTOR_CANCELLED_MISSION,
    });
  }

  get formattedMissionDate(): string {
    return formatDate(this.DTR_DATE_MISSION, 'fullDate', Jaf.LAN_CODE);
  }

  get infos(): BourseDtr {
    if (typeof this.DTR_INFOS === 'string') {
      return JSON.parse(this.DTR_INFOS);
    }
    return this.DTR_INFOS;
  }

  get CMIS(): CMIS {
    return JSON.parse(this.infos.MIS_CMI);
  }

  get time(): Date {
    const timeString = this.infos.MIS_HEURE_DEBUT;
    const date       = new Date(this.DTR_DATE_MISSION);
    date.setHours(+timeString.split(':')[0]);
    return date;
  }

  get passengerAmount(): number {
    return +this.CMIS.NB_ADULTE + +this.CMIS.NB_ENFANT + +this.CMIS.NB_BEBE;
  }

  get luggageAmount(): number {
    return +this.CMIS.NBRE_BAGAGE_CABINE + +this.CMIS.NBRE_BAGAGE_SOUTE;
  }

  get requestedModelsLabels(): string {
    const requestedModels: string[] = [];
    if (this.infos.filter.MOE_ID) {
      const chosenBrands = this.concept.cm
        .getConcept('C_Gds_Modele')
        .detectWholeBrand(this.infos.filter.MOE_ID);
      const chosenModels = this.infos.filter.MOE_ID.filter(
        (id) => !chosenBrands.some((brand) => brand[1].includes(id)),
      );
      chosenBrands.forEach((brand) => {
        requestedModels.push(`${brand[0]} (marque)`);
      });
      chosenModels.forEach((modelId) => {
        const vehicleModel = this.concept.cm.getConcept('C_Gds_Modele').getRow(modelId);
        if (vehicleModel) {
          requestedModels.push(
            `${vehicleModel.MOE_MAR_ID.MAR_LIBELLE} ${vehicleModel.MOE_LIBELLE}`,
          );
        }
      });
    }
    return requestedModels.length ? requestedModels.join(', ') : 'Tous véhicules acceptés';
  }

  get equipmentLabels(): string {
    const equipmentLabels: string[] = [];
    if (this.infos.filter.EQU_ID) {
      this.infos.filter.EQU_ID.forEach((equipmentId) => {
        const equipment = this.concept.cm.getConcept('C_Gds_Equipement').getRow(equipmentId);
        if (equipment) {
          equipmentLabels.push(equipment.EQU_LIBELLE);
        }
      });
    }
    return equipmentLabels.length ? equipmentLabels.join(', ') : 'Aucun équipement spécifié';
  }

  get requirementsArray(): { key: string; amount: number }[] {
    const requirements: { key: string; amount: number }[] = [];
    const queriedRequirements                             = [
      'NB_HANDICAPE_ASSISTE',
      'NB_HANDICAPE_FAUTEUIL',
      'NB_SIEGE_AUTO',
      'NB_SIEGE_BEBE',
      'NB_SIEGE_REHAUSSEUR',
    ];
    Object.entries(this.CMIS).forEach(([key, value]) => {
      if (queriedRequirements.includes(key) && value) {
        requirements.push({
          key   : `DIC_WAYD_CMI_LABEL_${key}`,
          amount: value,
        });
      }
    });
    return requirements;
  }

  get missionSteps(): CGenEtapePresenceRow[] {
    const database                      = this.concept.cm.getDatabase();
    const conceptLocation               = this.concept.cm.getConcept('C_Geo_Lieu');
    const steps: CGenEtapePresenceRow[] = [];
    const missionMock                   = new CGenMissionRow(
      this.concept.cm.getConcept('C_Gen_Mission'),
      {
        MIS_ID         : this.infos.DTR_DO_MIS_ID,
        MIS_DATE_DEBUT : this.infos.MIS_DATE_DEBUT,
        MIS_HEURE_DEBUT: this.infos.MIS_HEURE_DEBUT,
        MIS_HEURE_FIN  : this.infos.MIS_HEURE_FIN,
      },
      this.database,
    );
    this.infos.epr.forEach((epr: Epr) => {
      const step     = new CGenEtapePresenceRow(
        this.concept.cm.getConcept('C_Gen_EtapePresence'),
        {},
        database,
      );
      const location = new CGeoLieuRow(
        conceptLocation,
        {
          LIE_LIBELLE : epr.LIE_LIBELLE,
          LIE_TLI_ID  : epr.LIE_TLI_ID,
          LIE_FORMATED: epr.LIE_FORMATED,
          LIE_LAT     : epr.LIE_LAT,
          LIE_LNG     : epr.LIE_LNG,
          LIE_TIMEZONE: epr.LIE_TIMEZONE,
          LIE_PAY_ID  : epr.LIE_PAY_ID,
        },
        database,
      );
      step.setDatas(
        {
          EPR_LIE_ID     : location,
          EPR_MIS_ID     : missionMock,
          EPR_TRI        : epr.EPR_TRI,
          EPR_INFOS_VOL  : epr.EPR_INFOS_VOL,
          EPR_HEURE_DEBUT: epr.EPR_HEURE_DEBUT,
        },
        false,
      );
      steps.push(step);
    });
    return steps;
  }

  get asMission(): CGenMissionRow {
    const dtrAsMission = new CGenMissionRow(
      this.concept.cm.getConcept('C_Gen_Mission'),
      {
        MIS_DATE_DEBUT      : this.infos.MIS_DATE_DEBUT,
        MIS_HEURE_DEBUT     : this.infos.MIS_HEURE_DEBUT,
        MIS_HEURE_REEL_DEBUT: this.infos.MIS_HEURE_DEBUT,
        startTime           : this.DTR_DATE_MISSION,
      },
      this.database,
    );
    dtrAsMission.eprs  = this.missionSteps;
    dtrAsMission.build_etapes();
    return dtrAsMission;
  }

  get language(): CGenCategorieChauffeurRow {
    // iannis : j'ai return null sinon ca fait planter tout si on a pas de filtre de type CCH_ID
    if (!this.infos.filter.CCH_ID) return null;
    return this.concept.cm
      .getConcept('C_Gen_CategorieChauffeur')
      .languages.find(
        (language: CGenCategorieChauffeurRow) => language.CCH_ID === this.infos.filter.CCH_ID[0],
      );
  }

  get subContractor(): CGdsInstallationRow | null {
    if (this.DTR_ST_INS_ID) {
      return this.concept.cm.getConcept('C_Gds_Installation').getRow(this.DTR_ST_INS_ID);
    }
    return null;
  }

  get isCancelled(): boolean {
    return [
      CGdsDtrRow.DTR_SDT_SUBCONTRACTOR_REFUSED,
      CGdsDtrRow.DTR_SDT_CONTRACTOR_REFUSED,
      CGdsDtrRow.DTR_SDT_CONTRACTOR_CANCELLED,
      CGdsDtrRow.DTR_SDT_SUBCONTRACTOR_CANCELLED,
      CGdsDtrRow.DTR_SDT_CONTRACTOR_CANCELLED_MISSION,
      CGdsDtrRow.DTR_SDT_CONTRACTOR_ACCEPTED_CANCEL_REQUEST,
      CGdsDtrRow.DTR_SDT_CANCEL_ACKNOWLEDGED_BY_SUBCONTRACTOR,
    ].includes(this.DTR_SDT_ID);
  }

  get isInProcess(): boolean {
    return [
      CGdsDtrRow.DTR_SDT_PENDING,
      CGdsDtrRow.DTR_SDT_PENDING_PRICING,
      CGdsDtrRow.DTR_SDT_ACCEPTED,
      CGdsDtrRow.DTR_SDT_IN_PROGRESS,
      CGdsDtrRow.DTR_SDT_DONE,
      CGdsDtrRow.DTR_SDT_PENDING_CLIENT_VALIDATION,
      CGdsDtrRow.DTR_SDT_PENDING_AUTOMATIC_PROCESSING,
    ].includes(this.DTR_SDT_ID);
  }
}
