import { Injectable, Inject } from '@angular/core';
import { Gds, GdsResponse } from '@way-lib-jaf/gds';
import { ConceptManager } from '@way-lib-jaf/concept-manager';
import { CGenOptionclientRow } from '@way-lib-jaf/rowLoader';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AlertController, Platform } from '@ionic/angular';
import 'cordova-plugin-purchase';
import { TranslateService } from '@ngx-translate/core';

enum StoreError {
  BILLING_UNAVAILABLE = 'BILLING_UNAVAILABLE',
  USER_CANCELED = 'USER_CANCELED',
  ITEM_ALREADY_OWNED = 'ITEM_ALREADY_OWNED',
}

export const WAYPARTNER_SUBSCRIPTION_ANDROID_PRODUCT_ID = 'waypartner_android_monthly';
export const WAYPARTNER_SUBSCRIPTION_IOS_PRODUCT_ID = 'com.waynium.waypartner.monthly';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionsService {
  public subscriptions$: BehaviorSubject<CGenOptionclientRow[]> = new BehaviorSubject<
    CGenOptionclientRow[]
  >([]);

  public hasActiveSubscription$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public subscriptionEndDate$: BehaviorSubject<Date | null> = new BehaviorSubject<Date | null>(
    null,
  );

  public isPurchasing$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public storeProduct$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  public productPrice$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public store!: CdvPurchase.Store;

  private initializedSubscriptions: boolean = false;

  constructor(
    private gds: Gds,
    private cm: ConceptManager,
    private http: HttpClient,
    private plt: Platform,
    private alertController: AlertController,
    private router: Router,
    private translate: TranslateService,
    @Inject('apiRootUrl') private apiRootUrl
  ) {}

  public initialize() {
    this.store = CdvPurchase.store;
    this._initializeStores().then(() => {
      this._initializeIAP();
    });
  }

  public fetchSubscriptions(): Observable<GdsResponse | null> {
    if (this.subscriptions$.getValue().length || this.initializedSubscriptions) return of(null);
    this.initializedSubscriptions = true;
    return this.gds.post('divers', '/wayp/get-abonnement').pipe(
      take(1),
      // eslint-disable-next-line consistent-return
      tap((rawSubscriptions: any) => {
        if (!Object.values(rawSubscriptions).length) return null;
        const subscriptions = this._computeSubscriptions(rawSubscriptions);
        this.hasActiveSubscription$.next(this.hasActiveSubscription(subscriptions));
        this.subscriptionEndDate$.next(this.getSubscriptionEndDate(subscriptions));
        this.subscriptions$.next(subscriptions);
      }),
    );
  }

  public hasActiveSubscription(subscriptions: CGenOptionclientRow[]): boolean {
    this._sortSubscriptions(subscriptions);

    if (!subscriptions.length) {
      return false;
    }

    const endDate     = subscriptions[0].OPI_FIN;
    const currentDate = new Date();
    endDate.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);
    return endDate >= currentDate;
  }

  public getSubscriptionEndDate(subscriptions: CGenOptionclientRow[]): Date | null {
    return subscriptions[0].OPI_FIN ?? null;
  }

  public refreshProduct(product: CdvPurchase.Product): void {
    if (!product) {
      return;
    }
    this.storeProduct$.next(product);
    const productPrice = (product?.offers[0]?.pricingPhases[0]?.priceMicros ?? 0) / 1000000;
    this.productPrice$.next(productPrice.toFixed(2));
  }

  public purchaseSubscription(): void {
    const product = this.storeProduct$.getValue();
    if (!product) {
      this._presentAlert('Erreur', 'Produit non trouvé. Veuillez réessayer.');
      return;
    }
    this.isPurchasing$.next(true);
    product.getOffer().order();
  }

  private _showPurchaseErrorMessage(
    message: string = "Une erreur est survenue lors de l'achat. Veuillez réessayer.",
  ): void {
    if (this.isPurchasing$.getValue()) {
      this._presentAlert('Erreur', message);
      this.isPurchasing$.next(false);
    }
  }

  private _onSubscribeSuccess(subscription: CGenOptionclientRow): void {
    const subscriptions = this._computeSubscriptions([subscription]);
    this.hasActiveSubscription$.next(this.hasActiveSubscription(subscriptions));
    this.subscriptionEndDate$.next(this.getSubscriptionEndDate(subscriptions));
    this.subscriptions$.next(subscriptions);
  }

  private _computeSubscriptions(subs: any): CGenOptionclientRow[] {
    const computedSubs: CGenOptionclientRow[] = [];
    Object.values(subs).forEach((sub: CGenOptionclientRow) => {
      const subscription = new CGenOptionclientRow(
        this.cm.getConcept('C_Gen_Optionclient'),
        {
          OPI_ID     : sub.OPI_ID,
          OPI_LIBELLE: sub.OPI_LIBELLE,
          OPI_DEBUT  : sub.OPI_DEBUT,
          OPI_FIN    : sub.OPI_FIN,
          OPI_OPT_ID : sub.OPI_OPT_ID,
        },
        this.cm.getDatabase(),
      );
      computedSubs.push(subscription);
    });
    this._sortSubscriptions(computedSubs);
    return computedSubs;
  }

  private _sortSubscriptions(subscriptions: CGenOptionclientRow[]): void {
    subscriptions.sort((subA, subB) => {
      return subA.OPI_FIN > subB.OPI_FIN ? -1 : 1;
    });
  }

  private _initializeStores(): Promise<void> {
    const { ProductType, Platform: PurchasePlatform } = CdvPurchase;
    return new Promise((resolve, reject) => {
      try {
        this.store.register([
          {
            id      : WAYPARTNER_SUBSCRIPTION_ANDROID_PRODUCT_ID,
            type    : ProductType.PAID_SUBSCRIPTION,
            platform: PurchasePlatform.GOOGLE_PLAY,
          },
          {
            id      : WAYPARTNER_SUBSCRIPTION_IOS_PRODUCT_ID,
            type    : ProductType.PAID_SUBSCRIPTION,
            platform: PurchasePlatform.APPLE_APPSTORE,
          },
        ]);
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  private _initializeIAP(): void {
    const { Platform: PurchasePlatform } = CdvPurchase;
    this.store.initialize([PurchasePlatform.GOOGLE_PLAY, PurchasePlatform.APPLE_APPSTORE]);
    this.store.validator = (receipt, callback) => this._validatorMethod(receipt, callback);

    this.store.when().productUpdated((product) => {
      this.refreshProduct(product);
    });

    this.store
      .when()
      .approved((transaction) => {
        if (!transaction.isAcknowledged) {
          transaction.verify();
        }
      })
      .verified((receipt) => {
        receipt.finish();
        if (this.isPurchasing$.getValue()) {
          this._presentAlert('Succès', 'Votre achat a été effectué avec succès.');
          this.isPurchasing$.next(false);
          this.router.navigate(['/dashboard']);
        }
      });

    this.store.error((error) => {
      if (!this.isPurchasing$.getValue()) return;
      switch (error.message) {
        case StoreError.BILLING_UNAVAILABLE:
          this._showPurchaseErrorMessage();
          break;
        case StoreError.USER_CANCELED:
          this._presentAlert('Achat annulé', "Procédure d'achat annulée.");
          break;
        case StoreError.ITEM_ALREADY_OWNED:
          this._presentAlert('Erreur', 'Vous possédez déjà cet abonnement.');
          break;
        default:
          this._presentAlert('Erreur', 'Une erreur inattendue est survenue. Veuillez réessayer');
          break;
      }
      this.isPurchasing$.next(false);
    });
  }

  private async _validatorMethod(
    receipt: CdvPurchase.Validator.Request.Body,
    callback: CdvPurchase.Callback<CdvPurchase.Validator.Response.Payload>,
  ): Promise<void> {
    const transactionType = this.plt.is('ios') ? 'ios' : 'android';

    const transactionReceipt =
      transactionType === 'ios'
        ? (receipt.transaction as CdvPurchase.Validator.Request.ApiValidatorBodyTransactionApple).id
        : (receipt.transaction as CdvPurchase.Validator.Request.ApiValidatorBodyTransactionGoogle)
            .receipt;

    if (!transactionReceipt || !receipt.id) {
      callback({
        ok     : false,
        message: 'Purchase validation failed.',
      });
      this._showPurchaseErrorMessage(
        "Un erreur est survenue lors de la validation de l'achat. Veuillez réessayer.",
      );
      return;
    }

    const installationId = this.cm.getInstallationPrincipal().INS_ID;

    const purchaseToken =
      transactionType === 'ios' ? transactionReceipt : JSON.parse(transactionReceipt).purchaseToken;

    this._registerSubscription(purchaseToken, transactionType, installationId).subscribe({
      next: (newSubscription: CGenOptionclientRow) => {
        if (!newSubscription) {
          callback({
            ok     : false,
            message: 'Purchase validation failed.',
          });
          return;
        }

        this._onSubscribeSuccess(newSubscription);

        callback({
          ok  : true,
          data: {
            id            : receipt.id || '',
            latest_receipt: true,
            transaction   : receipt.transaction as CdvPurchase.Validator.Response.NativeTransaction,
            date          : new Date().toLocaleDateString(),
          },
        });
      },
      error: () => {
        this._showPurchaseErrorMessage(
          "Un erreur est survenue lors de l'enregistrement de l'achat. Veuillez réessayer.",
        );
      },
    });
  }

  private _registerSubscription(
    purchaseToken: string,
    platform: 'android' | 'ios',
    installationId: string,
  ): Observable<any> {
    return this.http.post(`${this.apiRootUrl.divers}/subscriptions/${platform}`, {
      purchaseToken,
      installationId,
    });
  }

  async _presentAlert(header, message) {
    const alert = await this.alertController.create({
      header : this.translate.instant(header),
      message: this.translate.instant(message),
      buttons: ['OK'],
    });
    await alert.present();
  }
}
