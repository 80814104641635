import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LangService } from '@way-lib/ui/lang.service';
import { ConceptManager } from './concept-manager';
import { Gds } from './gds';
import { Jaf } from './jaf';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export abstract class Controller {
  name: string;

  version: string;

  status = 0;

  frequence = 5000;

  dataLoaded = false;

  protected abstract actionLoadData: string;

  lastTimeRun: number;

  runIdProcess;

  started;

  obj = {};

  protected constructor(
    protected cm: ConceptManager,
    protected gds: Gds,
    protected translate: TranslateService,
    public authService: AuthService,
    public langService: LangService,
  ) {}

  execute() {
    switch (this.status) {
      case 0:
        this.initAffichage();
        break;
      case 1:
        this.initApi();
        break;
      case 2:
        this.initConnexion();
        break;
      case 3:
        this.initDataLocale();
        break;
      case 4:
        this.start();
        break;
      default:
        break;
    }
  }

  initAffichage() {
    // eslint-disable-next-line no-console
    console.log('[1] InitAffichage');
    this.status = 1;
    this.execute();
  }

  initApi() {
    // eslint-disable-next-line no-console
    console.log('[2] InitApi');
    this.status = 2;
    this.execute();
  }

  initConnexion() {
    // eslint-disable-next-line no-console
    console.log('[3] InitConnexion');
    this.status = 3;
    this.execute();
  }

  initDataLocale() {
    // eslint-disable-next-line no-console
    console.log('[4] InitDataLocale');
    this.cm.loadDataLocale().then(() => {
      this.status = 4;
      this.execute();
    });
  }

  run() {
    this.lastTimeRun = new Date().getTime();
    this.cm.synchronise(() => {
      if (!this.dataLoaded) {
        this.loadData();
      } else {
        this.cm.getConcept('C_Gds_EvenementChauffeur').loadNewEvent();
      }
      this.cm.onSynchro();
    });
  }

  checkIsRunning() {
    if (new Date().getTime() > this.lastTimeRun + this.frequence) this.run();
  }

  start() {
    if (!this.started) {
      this.started      = true;
      this.runIdProcess = setInterval(() => {
        this.checkIsRunning();
      }, this.frequence);
      setTimeout(() => {
        this.cm.getConcept('C_Gds_EvenementChauffeur').initNotification();
      }, 1500);
    }
    this.run();
  }

  initLanguages() {
    Jaf.LAN_CODE = 'en-US';
    this.translate.setDefaultLang('en');
    this.authService.isAuthenticated().then((authenticated) => {
      if (authenticated) {
        this.authService.loadChauffeurLanguage();
      }
    });
  }

  loadData(callback?: (arg0?: unknown)=>void) {
    this.cm.loadData(
      this.actionLoadData,
      null,
      (data) => {
        this.dataLoaded = true;
        if (typeof callback === 'function') callback(data);
        this.cm.getConcept('C_Gds_EvenementChauffeur').loadNewEvent();
      },
      () => {
        this.dataLoaded = false;
      },
    );
  }

  closeController() {
    this.cm.saveDataLocale();
    return 'Data save';
  }

  init() {
    this.status = 0;
    this.cm.setOnSynchroCallback(() => {
      if (this.cm.synchroDone && this.status < 4) {
        this.execute();
      }
    });
    this.execute();
  }
}
