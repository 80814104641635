import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CodeWayPartnerService {
  static decalage = 100000;

  static permute = [3, 18, 17, 0, 14, 16, 11, 7, 8, 9, 10, 6, 12, 13, 4, 15, 5, 2, 1];

  static binarize(value) {
    const decompositionBinaire = [];
    while (value > 0) {
      decompositionBinaire.push(value % 2);
      value = Math.floor(value / 2);
    }
    return decompositionBinaire;
  }

  static getNumberAfterPermutation(value) {
    let r = 0;
    this.binarize(value).forEach((v, index) => {
      // console.log(index,v);
      r += v === 1 ? 2**this.permute[index] : 0;
    });
    return r;
  }

  static getCodeByInsId(ins_id) {
    const result = this.decalage + this.getNumberAfterPermutation(ins_id);

    let formated = `${  result}`;
    while (formated.length < 6) {
      formated = `0${  formated}`; // Ajoute un zéro à gauche
    }
    return `${formated.substring(0, 3)  }-${  formated.substring(3, 6)}`;
  }

}
