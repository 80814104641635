import { JafConcept } from '@way-lib-jaf/concept';
import { CGdsModeleRow } from '@way-lib-jaf/rowLoader'

export class CGdsModele extends JafConcept {
  public primary = 'MOE_ID';

  protected name = 'nf_gds_modele';

  protected class = 'C_Gds_Modele';

  protected rowClass = 'CGdsModeleRow';

  protected trigramme = 'MOE';

  public _flagUniqueRowset = true;

  detectWholeBrand(modelList: string[]): [string, string[]][] {
    const chosenBrands: [string, string[]][] = []
    this.brandList.forEach((brand: [string, string[]]) => {
      if (brand[1].every((id) => modelList.includes(id))) {
        if (!chosenBrands.some((chosenBrand) => chosenBrand[0] === brand[0])) {
          chosenBrands.push(brand)
        }
      }
    })
    return chosenBrands
  }

  get brandList(): [string, string[]][] {
    const brandMap = new Map();
    (<CGdsModeleRow[]>this.all).forEach((model) => {
      const brandName = model.MOE_MAR_ID.MAR_LIBELLE
      if (!brandMap.has(brandName)) {
        brandMap.set(brandName, [])
      }
      brandMap.get(brandName).push(model.MOE_ID)
    })
    return Array.from(brandMap)
  }
}