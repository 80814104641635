import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@way-lib/common/services/auth/auth.guard';

const routes: Routes = [
  {
    path      : '',
    redirectTo: 'dashboard',
    pathMatch : 'full',
  },
  {
    path        : 'dashboard',
    canActivate : [AuthGuard],
    loadChildren: () =>
      import('./pages/dashboard/dashboard.module').then((m) => m.DashboardPageModule),
  },
  {
    path        : 'missions',
    canActivate : [AuthGuard],
    loadChildren: () =>
      import('@way-lib/mission/mission.module').then((m) => m.MissionModule),
  },
  {
    path        : 'test',
    canActivate : [AuthGuard],
    loadChildren: () => import('./pages/test/test.module').then((m) => m.TestPageModule),
  },
  {
    path        : 'notifications',
    canActivate : [AuthGuard],
    loadChildren: () =>
      import('@way-lib/notification/notifications.module').then(
        (m) => m.NotificationsPageModule,
      ),
  },
  {
    path        : 'calendar',
    canActivate : [AuthGuard],
    loadChildren: () =>
      import('./pages/calendar/calendar.module').then((m) => m.CalendarPageModule),
  },
  {
    path        : 'auth',
    loadChildren: () => import('./pages/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path        : 'faq',
    loadChildren: () => import('./pages/faq/faq.module').then((m) => m.FaqPageModule),
  },
  {
    path        : 'cgu',
    loadChildren: () => import('./pages/cgu/cgu.module').then((m) => m.CguPageModule),
  },
  {
    path        : 'code',
    canActivate : [AuthGuard],
    loadChildren: () => import('./pages/code/code.module').then((m) => m.CodePageModule),
  },
  {
    path        : 'geolocation',
    canActivate : [AuthGuard],
    loadChildren: () =>
      import('./pages/geolocation/geolocation.module').then((m) => m.GeolocationPageModule),
  },
  {
    path        : 'agenda',
    canActivate : [AuthGuard],
    loadChildren: () =>
      import('@way-lib/agenda-driver/agenda-driver.module').then(
        (m) => m.AgendaDriverModule,
      ),
  },
  {
    path        : 'geolocation-optin',
    canActivate : [AuthGuard],
    loadChildren: () =>
      import('./pages/geolocation-optin/geolocation-optin.module').then(
        (m) => m.GeolocationOptinPageModule,
      ),
  },
  {
    path        : 'panneau/:panneau/:urlLogo',
    canActivate : [AuthGuard],
    loadChildren: () =>
      import('@way-lib/client/pages/panneau/panneau.module').then(
        (m) => m.PanneauPageModule,
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
