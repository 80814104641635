import { Component, EventEmitter, HostBinding, Inject, Input, OnInit, Output } from '@angular/core';
import { CGdsEvenementChauffeur } from '@way-lib-jaf/conceptLoader';
import { ConceptManager } from '../../services/jaf/concept-manager';
import { SubscriptionsService } from '../../services/subscriptions.service';
import { WayMode, WayModeService } from '../../services/way-mode.service';

@Component({
  selector   : 'way-header',
  templateUrl: './way-header.component.html',
  styleUrls  : ['./way-header.component.scss'],
})
export class WayHeaderComponent implements OnInit {
  @Input() color = 'regular';

  @Input() showBackButton: boolean;

  @Input() disableMenu: boolean = false;
  
  @Input() defaultHref: string | Array<string | number> = ""
  
  @Input() useCustomNavigation: boolean = false;

  @Output() backButtonClick = new EventEmitter();

  @HostBinding('className') className = 'regular';

  public conceptEvc: CGdsEvenementChauffeur;

  WayMode = WayMode;

  get defaultHrefToString() {
    if (typeof this.defaultHref === 'string') {
      return this.defaultHref;
    }
    return this.defaultHref.join('/');
  }

  constructor(
    public cm: ConceptManager,
    public subscriptionService: SubscriptionsService,
    public wayModeService: WayModeService,
    @Inject('WaySuffix') public suffix: string,
  ) {}

  ngOnInit() {
    this.className  = this.color;
    this.conceptEvc = this.cm.getConcept('C_Gds_EvenementChauffeur');
  }

  emitCustomNavigationEvent():void {
    this.backButtonClick.emit()
  }

}

