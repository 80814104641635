import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NumReservationPipe } from '@way-lib/mission/pipes/numReservation.pipe';
import { LeadZeroPipe } from './lead-zero.pipe';
import { FileExtensionPipe } from './file-extension.pipe';
import { AgePipe } from './age.pipe';
import { FileNamePipe } from './file-name.pipe';
import { AbsolutePipe } from './absolute.pipe';
import { JafTranslate } from './jafTranslate.pipe';
import { PhoneNumberPipe } from './phone-number.pipe';
import { TypeRegleAgendaFlagWaydPipe } from './type-regle-agenda-flag-wayd';
import { LanguagePipe } from './language.pipe';
import { CountryNamePipe } from './country.pipe';

@NgModule({
  declarations: [
    LeadZeroPipe,
    FileExtensionPipe,
    AgePipe,
    FileNamePipe,
    AbsolutePipe,
    JafTranslate,
    PhoneNumberPipe,
    LanguagePipe,
    TypeRegleAgendaFlagWaydPipe,
    CountryNamePipe,
    NumReservationPipe,
  ],
  imports: [CommonModule],
  exports: [
    LeadZeroPipe,
    FileExtensionPipe,
    AgePipe,
    FileNamePipe,
    AbsolutePipe,
    JafTranslate,
    PhoneNumberPipe,
    LanguagePipe,
    TypeRegleAgendaFlagWaydPipe,
    CountryNamePipe,
    NumReservationPipe,
  ],
})
export class PipesModule {}
