import { JafConcept } from '@way-lib-jaf/concept';

export class CGenEntiteCommerciale extends JafConcept {
  public primary = 'ECO_ID';

  protected name = 'nf_gen_entiteCommerciale';

  protected class = 'C_Gen_EntiteCommerciale';

  protected rowClass = 'CGenEntiteCommercialeRow';

  protected trigramme = 'ECO';
}
