import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { Platform } from '@ionic/angular';
import BackgroundGeolocation from "@transistorsoft/capacitor-background-geolocation";
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Geolocation, Position } from '@capacitor/geolocation';

import { CGeoLieuRow } from '@way-lib-jaf/rowLoader';
import { ConceptManager } from '@way-lib-jaf/concept-manager';
import { Gds } from '@way-lib-jaf/gds';

@Injectable({
  providedIn: 'root',
})
export class GeolocationService {
  public enabled = false;

  public backgroundIsReady = false;

  public lastTimeSendLocation = 0;

  constructor(
    private platform: Platform,
    private storage: Storage,
    private cm: ConceptManager,
    private gds: Gds,
    private translate: TranslateService,
  ) {
    this.storage.create().then(() => {
      this.storage.get('geolocationService.enabled').then((enabled) => {
        this.enabled = Boolean(enabled);
      });
    });
  }

  stop() {
    if (this.isEnabled() && this.backgroundIsReady) {
      BackgroundGeolocation.stop();
    }
  }

  isEnabled() {
    return this.enabled;
  }

  async getState() {
    if (this.platform.is('capacitor')) {
      return BackgroundGeolocation.getState();
    }
    return {enabled: true,trackingMode: ''}
  }

  setEnable(value) {
    this.enabled = value;
    this.cm.getConcept('C_Gds_EvenementChauffeur')
      .paramsToSend.flagDispo = this.enabled ? '1' : '0';
    this.gds.post('divers', '/gdsv3/chauffeur-compte', {CHU_FLAG_DISPO: this.enabled  ? '1' : '0'});
    this.storage.set('geolocationService.enabled', this.enabled);
  }

  enable() {
    this.setEnable(true);
    this.initGeolocation();
  }

  disable() {
    if (this.enabled) {
      this.setEnable(false);
      if (this.backgroundIsReady) BackgroundGeolocation.stop();
    }
  }

  initGeolocation() {
    // eslint-disable-next-line no-console
    console.log('initGeolocation');
    this.watchPosition().subscribe((location) => {
      if ( new Date().getTime() - this.lastTimeSendLocation > 60000 ) {
        const request =  this.gds.post(
          'geolocalisation',
          '/geolocation/set-position-chauffeur',
          { location }
          );

        request.subscribe(
          () => {
            this.lastTimeSendLocation = new Date().getTime();
          },
        );

      }
    });
  }

  addGeofence(lie: CGeoLieuRow) {
    BackgroundGeolocation.addGeofence({
      identifier   : lie.LIE_ID,
      radius       : 300,
      latitude     : lie.LIE_LAT,
      longitude    : lie.LIE_LNG,
      notifyOnEntry: true,
      notifyOnExit : true,
    })
      .then(() => {})
      .catch((error) => {
        console.error('[addGeofence] FAILURE: ', error);
      });
  }

  removeGeofence(lie: CGeoLieuRow) {
    BackgroundGeolocation.removeGeofence(lie.LIE_ID).then(() => {
      // console.log('[removeGeofence] success');
    });
  }

  async getCurrentPosition() {
    if (this.backgroundIsReady) {
      return BackgroundGeolocation.getCurrentPosition({
        timeout        : 30, // 30 second timeout to fetch location
        maximumAge     : 5000, // Accept the last-known-location if not older than 5000 ms.
        desiredAccuracy: 100, // Try to fetch a location with an accuracy of `10` meters.
        samples        : 3, // How many location samples to attempt.
      });
    }
    return new Promise((resolve) => {
      setTimeout(() => {
        this.getCurrentPosition().then((location) => {
          resolve(this.getPositionFormated(location));
        });
      }, 3000);
    });
  }

  forceRecuperationPosition(observer) {
    BackgroundGeolocation.getCurrentPosition({ samples: 1, maximumAge: 600000, persist: true }).then(
      (location) => {
        if (this.isEnabled()) {
          const position = this.getPositionFormated(location);
          observer.next(position);
        } else {
          observer.complete();
        }
      },
    );
  }

  getPositionFormated(location): Position {
    const position: Position = {
      coords: {
        latitude        : location.coords.latitude,
        longitude       : location.coords.longitude,
        accuracy        : location.coords.accuracy,
        altitude        : location.coords.altitude,
        speed           : location.coords.speed,
        heading         : null,
        altitudeAccuracy: null,
      },
      timestamp: new Date(location.timestamp).getTime(),
    };
    return position;
  }

  watchPosition() {
    return new Observable((observer) => {
      if (!this.isEnabled()) {
        observer.complete();
      }
      if (this.platform.is('capacitor')) {
        BackgroundGeolocation.onLocation((location) => {
          if (!this.isEnabled()) {
            observer.complete();
          }
          observer.next(this.getPositionFormated(location));
        },(r)=>{
          console.error('erreur onLocation',r);
          observer.error(r);
        });

        // BackgroundGeolocation.onGeofence((geofence) => {
        //   if (geofence.action === 'ENTER') {
        //     // Entering the danger-zone, we want to aggressively track location.
        //     BackgroundGeolocation.setConfig({
        //       distanceFilter: 3,
        //     });
        //   } else if (geofence.action === 'EXIT') {
        //     // Exiting the danger-zone, we resume geofences-only tracking.
        //     BackgroundGeolocation.setConfig({
        //       distanceFilter: 10,
        //     });
        //   }
        // });

        BackgroundGeolocation.onHeartbeat(() => {
          this.forceRecuperationPosition(observer);
          // You could request a new location if you wish.
        });

        const params = this.gds.getParamsTokenVide();
        const config = {
          reset                        : true,
          debug                        : false,
          logLevel                     : BackgroundGeolocation.LOG_LEVEL_VERBOSE,
          desiredAccuracy              : BackgroundGeolocation.DESIRED_ACCURACY_HIGH,
          backgroundPermissionRationale: {
            title: this.translate.instant("Permettre à {applicationName} d'accéder à votre localisation en arrière plan ?"),
            message:
        this.translate.instant( "Pour reçevoir des missions {applicationName} lorsque vous êtes hors de l'application, vous devez activer les permissions pour {backgroundPermissionOptionLabel} "),
            positiveAction: this.translate.instant('Changez pour {backgroundPermissionOptionLabel}'),
            negativeAction: this.translate.instant('Annuler'),
          },
          distanceFilter               : 15,
          locationUpdateInterval       : 5000,
          fastestLocationUpdateInterval: 5000,
          desiredOdometerAccuracy      : 10,
          preventSuspend               : true,
          heartbeatInterval            : 60,
          stopOnTerminate              : false,
          startOnBoot                  : true,
          notification                 : {
            title    : this.translate.instant('Service de localisation'),
            text     : this.translate.instant('Le service est actuellement activé'),
            smallIcon: '@drawable/ic_notification', // <-- defaults to app icon
            largeIcon: '@drawable/ic_notification',
          },
          url: this.gds.getUrlRequest(
            'geolocalisation',
            `/geolocation/set-position-chauffeur/${params.ach_id}/${params.token}`,
          ),
        }
        if (this.platform.is('android')) {
          config.distanceFilter = 0;
        }

        BackgroundGeolocation.ready(config).then((state) => {
          BackgroundGeolocation.changePace(true);
          if (!state.enabled) {
            // 3.  Start tracking.
            BackgroundGeolocation.start().then(r => {
              // eslint-disable-next-line no-console
              console.debug('success', r)
            }).catch(r => {
              console.error('error', r)
              observer.error(r);
            });
          }
          this.backgroundIsReady = true;
        }).catch((r)=>{
          console.error('erreur BackgroundGeolocation.ready',r);
          observer.error(r);
        });
      } else {
        // eslint-disable-next-line no-console
        console.log('Activation geoloc web');
        const config: PositionOptions = {
          enableHighAccuracy: true
        }
        Geolocation.watchPosition(config, (position) => {
          if (!position) {
            console.error('Autorisation refusée');
            observer.error();
            return;
          }
          if (this.isEnabled()) {
            observer.next(this.getPositionFormated(position));
          } else {
            observer.complete();
          }
        }).then((r)=> {
          // eslint-disable-next-line no-console
          console.log('then Geolocation.watchPosition', r);
        });
      }
    });
  }
}
