import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { CountryISO, SearchCountryField, PhoneNumberFormat } from '@capgo/ngx-intl-tel-input';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector   : 'way-input-mobile',
  templateUrl: './way-input-mobile.component.html',
  styleUrls  : ['./way-input-mobile.component.scss'],
})
export class WayInputMobileComponent implements OnInit {
  @Input() size: string = '';

  @Input() phoneNumber!: string;

  @Input() placeholder = '06 12 34 56 78'

  @Output() phoneData = new EventEmitter<{ internationalFormat: string; normalFormat: string }>();

  @Output() isPhoneNumberValid = new EventEmitter<boolean>();

  @Output() countryCode = new EventEmitter<string>();

  searchCountryField = [SearchCountryField.Iso2, SearchCountryField.Name];

  selectedCountryISO = CountryISO.France;

  searchCountryPlaceholder = 'Chercher pays';

  isInvalid = false;

  separateDialCode = false;

  SearchCountryField = SearchCountryField;

  CountryISO = CountryISO;

  PhoneNumberFormat = PhoneNumberFormat;

  preferredCountries = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
    CountryISO.France,
    CountryISO.Italy,
    CountryISO.Switzerland,
    CountryISO.Belgium,
    CountryISO.Netherlands,
  ];

  phoneForm!: FormGroup;

  ngOnInit() {
    this.phoneForm = new FormGroup({
      phone: new FormControl(this.phoneNumber, [Validators.required]),
    });
  }

  onChange($event) {
    if ($event) {
      const internationalFormat = $event.internationalNumber;
      const normalFormat        = $event.number;
      const isValid             = this.phoneForm.valid;
      this.isInvalid            = !isValid;
      this.phoneData.emit({ internationalFormat, normalFormat });
      this.isPhoneNumberValid.emit(isValid);
      this.countryCode.emit($event.countryCode);
    } else {
      this.phoneData.emit({ internationalFormat: '', normalFormat: '' });
    }
  }

  onCountryChange($event) {
    this.countryCode.emit($event.iso2.toUpperCase());
  }
}
