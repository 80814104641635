export const TENANT_ID_NUMBER = 100000000000;

export const IONIC_STORAGE_PREFIX = 'way-partner/_ionickv';
export const STORAGE_KEY_INS_COUNTRY_CODE = 'ins.country-code';
export const STORAGE_KEY_ACHID = 'wayd.ach_id';
export const STORAGE_KEY_INS = 'limos'
export const SENTRY_KEY_INSID = 'wayd.ins_id';
export const IONIC_STORAGE_KEY_ACHID = `${IONIC_STORAGE_PREFIX}/${STORAGE_KEY_ACHID}`;


// noinspection JSUnusedGlobalSymbols
export enum ClassEventId {
  INSERT = 1,
  UPDATE = 2,
  DELETE = 3,
  FOCUS = 4,
  BLUR = 5,
  ALTER = 6,
  LOG = 7,
}