import { CGdsCercleRow , CGdsInstallationRow } from '@way-lib-jaf/rowLoader';
import { JafRow } from '@way-lib-jaf/row';
import { WayChamp } from '@way-lib/common/decorator/WayChamp';
import { WayBuild } from '@way-lib/common/decorator/WayBuild';

export class CGdsPartenaireCercleRow extends JafRow {
  @WayChamp({
    label: '%LAB_GDS_PARTENAIRECERCLE_ID%',
    class: 'Id',
  })
  public PAC_ID;

  @WayChamp({
    label  : '%LAB_GDS_CERCLE_CER_ID%',
    class  : 'Liaison',
    liaison: 'C_Gds_Cercle',
    indexed: true,
  })
  public PAC_CER_ID: CGdsCercleRow;

  @WayChamp({
    label  : '%LAB_GDS_INSTALLATION_INS_ID%',
    class  : 'Liaison',
    liaison: 'C_Gds_Installation',
    indexed: true,
  })
  public PAC_INS_ID: CGdsInstallationRow;

  @WayBuild(['PAC_ID'])
  build_pacs() {
    if (typeof this.PAC_CER_ID === 'object') {
      this.PAC_CER_ID.launchBuildByRow(['build_partners']);
    }
  }
}